<template>
  <el-form ref="form" :model="inStoreForm" label-width="96px" :rules="rules">
    <div class="wrap" id="wrap" v-loading="loading">
      <!-- 顶部按钮 -->
      <topOperatingButton :isImportOrderBtn="showImportBill" :disabled="disabled" :showAudit="showAudit"
        :isAddBtn="showAdd" @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd"
        @getQuit="getQuit" @getImportOrder="getImportOrder" id="topOperatingButton" />
      <!-- 基本信息盒子 -->
      <cardTitleCom cardTitle="基本信息" id="basicInformation" :billStatus="inStoreForm.billStatus">
        <template slot="cardContent">
          <!-- 表格搜索条件 -->
          <!-- 第一行 -->
          <div class="x-w marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" :disabled="true" v-model="inStoreForm.billNo" placeholder="单据编号"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="单据日期" prop="billDate">
              <el-date-picker style="width: 170px" v-model="inStoreForm.billDate" type="date" placeholder="单据日期"
                :disabled="disabled" value-format="yyyy-MM-dd" size="mini">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="手工单据号" prop="handBillNo">
              <el-input class="inputWidth" :disabled="disabled" v-model="inStoreForm.handBillNo" placeholder="手工单据号"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="生产工厂" prop="produceDeptId">
              <SelectRemote @selectChange="changeProduceGroupChange" v-model="inStoreForm.produceDeptId"
                style="width: 250px" :option="
                  $select({
                    key: 'listProduceDept',
                    option: {
                      option: {
                        disabled: disabled || isImportSource,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="部门" prop="deptId">
              <SelectLocal v-model="inStoreForm.deptId" style="width: 250px" :option="
                  $select({
                    key: 'listSimpleQuery',
                    option: {
                      option: {
                        disabled: disabled,
                        showItem: [
                          {
                            deptId: inStoreForm.deptId,
                            deptName: inStoreForm.deptName,
                          },
                        ],
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="备注" prop="billRemark">
              <el-input class="inputRemark" :disabled="disabled" v-model="inStoreForm.billRemark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 5 }" size="mini" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>

      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="rightCardTitle">
          <div class="marR10 x-f">
            <el-button :disabled="disabled || !inStoreForm.produceDeptId" type="primary" size="mini"
              @click="getDistribution(1)" class="marL10">批量修改仓库</el-button>
          </div>
        </template>
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <!-- :max-height="tableHeight"
              :height="tableHeight" -->
          <!-- show-summary
              :summary-method="getSummaries"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
              @selection-change="handleSelectionChange"
              @row-click="handleRowClick" -->
          <div class="goodsMessage">
            <vxe-table border show-footer show-header-overflow ref="multipleTable"
              :data="inStoreForm.details" :loading="loadingTable" :footer-method="footerMethod"
              @checkbox-change="handleSelectionChange" @checkbox-all="handleSelectionChange" max-height="600px"
              height="600px" :checkbox-config="{
                trigger: 'row',
                checkMethod: () => !disabled,
              }" :row-config="{ isHover: true, height: 60 }" :sort-config="{ trigger: 'cell' }"
              :scroll-y="{ enabled: true }">
              <vxe-column fixed="left" type="checkbox" width="60" align="center" />
              <vxe-column fixed="left" align="center" :title="tableCellLabel" width="80">
                <template v-slot="scope">
                  <div @mouseenter="cellMouseEnter(scope.row)" @mouseleave="cellMouseLeave(scope.row)">
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click.stop="row('push', scope.rowIndex)"
                      class="el-icon-circle-plus operatePush"></i>
                    <i v-show="scope.row.hoverRow" :disabled="disabled" @click.stop="row('del', scope.rowIndex)"
                      class="el-icon-remove operateDel"></i>
                    <div v-show="!scope.row.hoverRow">
                      {{ scope.rowIndex + 1 }}
                    </div>
                  </div>
                </template>
              </vxe-column>
              <vxe-column fixed="left" title="商品编码" min-width="180" align="center" field="goodsNo">
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.rowIndex + '.goodsNo'" :rules="rules[`details.goodsNo`]">
                    <SelectRemote :disabled="disabled" v-model="scope.row.goodsNo" :option="optionGoods(scope.row)" 
                      :ref="'goodsNo'+'_'+scope.rowIndex +'_1'"
                      @handleSelectKeydown="handleTableKeydown($event, scope.row,'goodsNo',scope.rowIndex,1)"/>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column fixed="left" title="商品名称" align="center" field="goodsName" min-width="150" />
              <vxe-column fixed="left" title="商品条码" align="center" field="barcode" min-width="150" />
              <vxe-column title="规格" align="center" field="goodsSpec" width="120"><template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>规格</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.rowIndex + '.goodsSpec'" :rules="rules[`details.goodsSpec`]">
                    <el-select v-model="scope.row.goodsSpec" :disabled="disabled" size="mini"
                      :ref="'goodsSpec'+'_'+scope.rowIndex +'_4'"
                      @keydown.native="handleTableKeydown($event, scope.row,'goodsSpec',scope.rowIndex,4)"
                      @change="changeUnits('goodsSpec', scope.row, scope.row.goodsSpec)">
                      <el-option v-for="item in scope.row.units" :key="item.goodsSpec" :label="item.goodsSpec"
                        :value="item.goodsSpec" />
                    </el-select>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="生产日期" align="center" field="produceDate" width="180">
                <template slot-scope="scope">
                  <el-date-picker style="width: 150px" size="mini" v-model="scope.row.produceDate" type="date"
                      :ref="'produceDate'+'_'+scope.rowIndex +'_5'"
                      @keydown.native="handleTableKeydown($event,scope.row,'produceDate', scope.rowIndex,5)"
                    placeholder="单据日期" :disabled="disabled" value-format="yyyy-MM-dd">
                  </el-date-picker>
                </template>
              </vxe-column>
              <vxe-column title="单位" align="center" field="unitId" min-width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.rowIndex + '.unitId'" :rules="rules[`details.unitId`]">
                    <el-select size="mini" v-model="scope.row.unitId" :disabled="disabled"
                      :ref="'unitId'+'_'+scope.rowIndex +'_6'"
                      @keydown.native="handleTableKeydown($event,scope.row,'unitId', scope.rowIndex,6)"
                      @change="changeUnits('unitId', scope.row, scope.row.unitId)">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="源单单号" align="center" field="sourceBillNo" min-width="160" />
              <vxe-column title="生产批号" align="center" min-width="180" field="batchNo">
                <template slot-scope="scope">
                  <!-- <el-form-item
                    :prop="'details.' + scope.rowIndex + '.unitQty'"
                    :rules="rules[`details.unitQty`]"
                  > -->
                  <el-input @click.native.stop v-model="scope.row.batchNo" :disabled="disabled" size="mini"
                  :ref="'batchNo'+'_'+scope.rowIndex +'_8'" 
                    @keydown.native="handleTableKeydown($event,scope.row, 'batchNo',scope.rowIndex,8)"
                    maxlength="20"></el-input>
                  <!-- </el-form-item>s -->
                </template>
              </vxe-column>

              <vxe-column title="生产计划数" align="center" min-width="100" field="importUnitQty" />
              <vxe-column title="入库仓库" align="center" min-width="150" field="inStoreId">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>入库仓库</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.rowIndex + '.inStoreId'" :rules="rules[`details.inStoreId`]">
                    <SelectRemote v-model="scope.row.inStoreId" style="width: 120px"
                      :disabled="disabled || !inStoreForm.produceDeptId" :option="
                        $select({
                          key: 'listProduceStore',
                          option: {
                            option: {
                              remoteBody: {
                                produceDeptId: inStoreForm.produceDeptId,
                              },
                              disabled: disabled,
                              showItem: [
                                {
                                  storeId: scope.row.inStoreId,
                                  produceStoreName: scope.row.inStoreName,
                                },
                              ],
                            },
                          },
                        }).option
                      " 
                      :ref="'inStoreId'+'_'+scope.rowIndex +'_10'"
                      @handleSelectKeydown="handleTableKeydown($event, scope.row,'outStoreId',scope.rowIndex,10)"/>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="已入库数" align="center" min-width="100" field="unitAlreadyInQty" />
              <vxe-column title="入库数量" align="center" min-width="100" field="unitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>入库数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'details.' + scope.rowIndex + '.unitQty'" :rules="rules[`details.unitQty`]">
                    <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent @click.native.stop
                      @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitQty" :disabled="disabled"
                      type="number" size="mini" maxlength="7"
                      :ref="'unitQty'+'_'+scope.rowIndex +'_12'"
                      @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,12)"
                      @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,12)"
                      @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,12)"
                      @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,12)"
                      @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'unitQty',scope.rowIndex,12)" @input="
                        limitInputlength(scope.row.unitQty, scope.row, 'unitQty', true)
                      " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                  </el-form-item>
                </template>
              </vxe-column>
              <vxe-column title="合格数量" align="center" min-width="100" field="unitEligibleQty" />
              <vxe-column title="生产次品" align="center" min-width="100" field="unitProduceInferiorQty">
                <template slot-scope="scope">
                  <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent @click.native.stop
                    @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitProduceInferiorQty"
                    :disabled="disabled" type="number" size="mini" maxlength="7" 
                    :ref="'unitProduceInferiorQty'+'_'+scope.rowIndex +'_14'"
                    @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'unitProduceInferiorQty',scope.rowIndex,14)"
                    @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'unitProduceInferiorQty',scope.rowIndex,14)"
                    @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'unitProduceInferiorQty',scope.rowIndex,14)"
                    @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'unitProduceInferiorQty',scope.rowIndex,14)"
                    @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'unitProduceInferiorQty',scope.rowIndex,14)"
                    @input="
                      limitInputlength(
                        scope.row.unitProduceInferiorQty,
                        scope.row,
                        'unitProduceInferiorQty',
                        true
                      )
                    " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                </template>
              </vxe-column>
              <vxe-column title="包装次品" align="center" min-width="100" field="unitPackInferiorQty">
                <template slot-scope="scope">
                  <el-input @mousewheel.native.prevent @DOMMouseScroll.native.prevent @click.native.stop
                    @keyup.native="onkeyupUnitQty(scope.row)" v-model="scope.row.unitPackInferiorQty"
                    :disabled="disabled" type="number" size="mini" maxlength="7"
                    :ref="'unitPackInferiorQty'+'_'+scope.rowIndex +'_15'" 
                    @keydown.native.up.prevent="handleTableKeydown($event,scope.row, 'unitPackInferiorQty',scope.rowIndex,15)"
                    @keydown.native.down.prevent="handleTableKeydown($event,scope.row, 'unitPackInferiorQty',scope.rowIndex,15)"
                    @keydown.native.left.prevent="handleTableKeydown($event,scope.row, 'unitPackInferiorQty',scope.rowIndex,15)"
                    @keydown.native.right.prevent="handleTableKeydown($event,scope.row, 'unitPackInferiorQty',scope.rowIndex,15)"
                    @keydown.native.enter.prevent="handleTableKeydown($event,scope.row, 'unitPackInferiorQty',scope.rowIndex,15)"
                     @input="
                      limitInputlength(
                        scope.row.unitPackInferiorQty,
                        scope.row,
                        'unitPackInferiorQty',
                        true
                      )
                    " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                </template>
              </vxe-column>

              <vxe-column title="备注" align="center" field="remark" min-width="130">
                <template slot-scope="scope">
                  <el-input @click.native.stop v-model="scope.row.remark" :disabled="disabled" size="mini"
                    :ref="'remark'+'_'+scope.rowIndex +'_16'" 
                    @keydown.native="handleTableKeydown($event,scope.row, 'remark',scope.rowIndex,16)"
                    maxlength="80"></el-input>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </el-form>
</template>

<script>
import { getNewDate } from "@/utils/newDate"; //单据日期
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import { getBillNo } from "@/api/codeRule"; //单据号
import {
  inStoreAddAPI,
  inStoreDetailAPI,
  inStoreUpdateAPI,
  inStoreUpdateStatusAPI,
} from "@/api/produce/bill/inStore"; //产品入库
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import Dialog from "@/components/Dialog";
import { limitInputlength, fcount, isNumber } from "@/utils";
import {
  getTableSelect, //下拉框
  getSelectAssign, //赋值
} from "@/utils/orderTableSelect.js"; //表格的下拉框公共配置
import { produceDeptList } from "@/api/produce/sets/produceDept"; //生产工厂
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import tableKeydown from "@/utils/mixin/tableKeydown";
export default {
  name: "inStoreDetail",
  dicts: ["App_is_use", "bill_status"],
  mixins: [tableKeydown],
  components: {
    cardTitleCom,
    topOperatingButton,
    SelectRemote,
    SelectLocal,
    Dialog,
  },
  data() {
    return {
      isImportSource: false, //是否引入源单
      //表格遮罩
      loadingTable: false,
      tableCellLabel: "序号",
      //弹窗配置
      dialogOptions: {
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
        formData: this.$dialog({ key: "goods" }),
      },
      showImportBill: false, //引入源单按钮
      showAdd: false, //新增按钮
      showAudit: true, //审核按钮禁用状态
      //表单禁用
      disabled: false,
      //标识已审核
      auditOrder: false,
      //标识已关闭
      stopOrder: false,
      //标识未审核
      noAuditOrder: false,
      //标识已删除
      deleteOrder: false,
      //标识已禁用
      disableOrder: false,
      // 遮罩层
      loading: false,
      //旧的表单
      oldForm: {},
      //调出仓库id
      ids: [],
      //调出仓库多选
      multiple: true,
      // 表单参数
      inStoreForm: {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billId: undefined, //单据id
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{}],
      },
      // 表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则配置单据类型",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "单据日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "生产工厂不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsNo": [
          {
            required: true,
            message: "商品编码不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.goodsSpec": [
          {
            required: true,
            message: "规格不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.inStoreId": [
          {
            required: true,
            message: "入库仓库不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "details.unitQty": [
          {
            required: true,
            message: "入库数量不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
      canKeyTableColumnList:[
        {key:'goodsNo',columnindex:1,componentRefName:'remoteSelect'},
        {key:'goodsSpec',columnindex:4,type:'select'},
        {key:'produceDate',columnindex:5,type:'date'},
        {key:'unitId',columnindex:6,type:'select'},
        {key:'batchNo',columnindex:8},
        {key:'inStoreId',columnindex:10,componentRefName:'remoteSelect'},
        {key:'unitQty',columnindex:12},
        {key:'unitProduceInferiorQty',columnindex:14},
        {key:'unitPackInferiorQty',columnindex:15},
        {key:'remark',columnindex:16}
      ],//可以键盘导航的表格列详情
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        // 重置数据
        vm.reset();
        //初始化数据
        await vm.getInitializeData();
        if (vm.$route.query.billId) {
          //获取单据详情
          const { data } = await inStoreDetailAPI(vm.$route.query.billId);
          //单据赋值
          vm.inStoreForm = data;
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.inStoreForm));
        }
      } else if (vm.$route.query.type === "inStoreImport") {
        vm.isImportSource = true;
        if (vm.$route.query.sourceGoodsList) {
          vm.$set(vm.inStoreForm, "details", JSON.parse(vm.$route.query.sourceGoodsList));

          if (vm.inStoreForm.details.length == 0) {
            vm.inStoreForm.details = [{}];
          } else {
            vm.inStoreForm.details = vm.inStoreForm.details.map(x => {
              vm.onkeyupUnitQty(x)
              return x
            })
          }
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.inStoreForm));
      } else {
        // 重置数据
        vm.reset();
        //初始化数据
        await vm.getInitializeData();
        await vm.getOrderBillNo();
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.inStoreForm = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.inStoreForm));
      }
      vm.loading = false;
    });
  },
  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.inStoreForm.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.inStoreForm));
    }
    next();
  },
  watch: {
    //侦听单据状态
    "inStoreForm.billStatus": {
      handler(newVal) {
        if (this.inStoreForm.billStatus == "2") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = false;
        } else if (this.inStoreForm.billStatus == "3") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁止全部输入按钮
          this.disabled = true;
        } else if (this.inStoreForm.billStatus == "0") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.inStoreForm.billStatus == "4") {
          //引入源单按钮
          this.showImportBill = false;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = false;
          //恢复全部输入
          this.disabled = false;
        } else if (this.inStoreForm.billStatus == "") {
          //引入源单按钮
          this.showImportBill = true;
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //恢复全部输入
          this.disabled = false;
        }
      },
      immediate: true,
    },
    "inStoreForm.details":{
      handler(val) {
        this.hasTableKey = val;
      },
      deep: true,
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
    //商品下拉框配置项
    optionGoods() {
      var that = this;
      return function (row) {
        return getTableSelect(
          {
            billDetailItems: this.inStoreForm.details,
            getMultipleGoods: this.getGoodS,
            row,
          },
          that
        );
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    // 在页面销毁前执行的逻辑
    this.oldForm = {};
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    changeUnits(name, row, val) {
      let obj = row.units.find((v) => v[name] == val);
      if (name == "goodsSpec") {
        this.$set(row, "unitId", obj.unitId);
      } else if (name == "unitId") {
        this.$set(row, "goodsSpec", obj.goodsSpec);
      }
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (
        this.inStoreForm.billStatus == "0" ||
        this.inStoreForm.billStatus == "" ||
        !this.inStoreForm.billStatus
      ) {
        this.$set(row, "hoverRow", true);
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave(row) {
      // 移除hover的事件
      if (
        this.inStoreForm.billStatus == "0" ||
        this.inStoreForm.billStatus == "" ||
        !this.inStoreForm.billStatus
      ) {
        this.$set(row, "hoverRow", false);
        this.tableCellLabel = "序号";
      }
    },
    limitInputlength,
    changeProduceGroupChange(v) {
      this.inStoreForm.details.forEach((item) => {
        item.inStoreId = undefined;
      });
    },
    //打开弹窗
    getDistribution(val) {
      if (this.multiple) return this.$message.warning("请选择商品");
      this.dialogOptions.title = "选择仓库";
      this.dialogOptions.formData = this.$dialog({
        key: "produceStore",
        option: {
          table: {
            ...this.$dialog({ key: "produceStore" }).table,
            radioSelect: true,
            mutiSelect: false,
          },
        },
      });
      this.dialogOptions.formData.table.pagination = {
        ...this.dialogOptions.formData.table.pagination,
        produceDeptId: this.inStoreForm.produceDeptId,
      };
      this.dialogOptions.show = true;
    },

    //弹窗确定事件
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.chooseOutStoreIdChange(
            this.dialogOptions.formData.table.check[0].storeId,
            this.dialogOptions.formData.table.check[0].produceStoreName
          );
          break;
        default:
          break;
      }
    },
    //批量修改仓库
    chooseOutStoreIdChange(chooseOutStoreId, produceStoreName) {
      try {
        this.loadingTable = true;
        const promises = this.inStoreForm.details.map(async (item) => {
          if (this.ids.includes(item.goodsId)) {
            this.$set(item, "inStoreId", chooseOutStoreId);
            this.$set(item, "inStoreName", produceStoreName);
          }
        });
        Promise.all(promises).then(() => {
          this.loadingTable = false;
        });
      } catch (error) {
        this.loadingTable = false;
      }
    },
    //当前行
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (!this.disabled) {
        this.$refs.multipleTable.toggleRowSelection(row);
      }
    },
    // 多选框选中数据
    handleSelectionChange() {
      const selection = this.$refs.multipleTable.getCheckboxRecords();
      this.ids = selection
        .map((item) => item.goodsId)
        .filter((ite) => typeof ite !== "undefined"); //单据id
      this.multiple = !this.ids.length;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap")?.clientHeight;
      this.topOperatingH = document.getElementById("topOperatingButton")?.clientHeight;
      this.basicH = document.getElementById("basicInformation")?.clientHeight;
    },
    //计算
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = [
          "importUnitQty",
          "unitAlreadyInQty",
          "unitQty",
          "unitEligibleQty",
          "unitProduceInferiorQty",
          "unitPackInferiorQty",
        ];
        const [key, orderStoreId] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            if (orderStoreId) {
              num = Number(
                x.storeItemCache?.find?.((x) => x.orderStoreId === orderStoreId)?.[key] ||
                0
              );
            }
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    footerMethod({ columns, data }) {
      // 返回一个二维数组的表尾合计 updateFooter
      let ol = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (
            [
              "importUnitQty",
              "unitAlreadyInQty",
              "unitQty",
              "unitEligibleQty",
              "unitProduceInferiorQty",
              "unitPackInferiorQty",
            ].includes(column.property) ||
            column.property?.indexOf?.(".") >= 0
          ) {
            return data
              .map((x) => x[column.property])
              .reduce((p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"), 0);
          }
          return "";
        }),
      ];

      return ol;
    },
    //领用数量
    onkeyupUnitQty(row) {
      //合格数： unitEligibleQty     生产次品：  unitProduceInferiorQty   包装次品：  unitPackInferiorQty
      let unitProduceInferiorQty = row.unitProduceInferiorQty || 0;
      let unitPackInferiorQty = row.unitPackInferiorQty || 0;
      let unitEligibleQty = row.unitQty - unitProduceInferiorQty - unitPackInferiorQty;
      unitEligibleQty = this.formatNumber(unitEligibleQty);
      this.$set(row, "unitEligibleQty", unitEligibleQty);
    },
    //选中的商品
    getGoodS(rows) {
      this.loadingTable = true;
      rows.forEach((v) => {
        this.$set(v, "unitQty", 0);
        this.$set(v, "unitPrice", v.purPrice);
        this.$set(v, "unitMoney", v.unitQty * v.unitPrice);
        v.hoverRow = false;
        v.remark = undefined;
      });
      this.inStoreForm.details = getSelectAssign(rows, this.inStoreForm.details);
      this.loadingTable = false;
    },
    //审核/删除/终止事件
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.inStoreForm.billStatus != "0") {
            this.$message.error("此单据不是未审核的状态哦~");
            return;
          }
          await this.submitForm(true, "audit");
          const obj = {
            billIds: [this.inStoreForm.billId],
            billStatus: "2",
            billType: "170203",
          };
          //发送审核api
          const { data } = await inStoreUpdateStatusAPI(obj);
          this.inStoreForm = data;
          // 弹出提示
          // this.$message.success('审核成功')
        } else if (name === "反审核") {
          if (this.inStoreForm.billStatus != "2") {
            this.$message.error("此单据不是已审核的状态哦~");
            return;
          }
          const obj = {
            billIds: [this.inStoreForm.billId],
            billStatus: "0",
            billType: "170203",
          };
          //发送反审核api
          const { data } = await inStoreUpdateStatusAPI(obj);
          this.inStoreForm = data;
          // 弹出提示
          this.$message.success("反审核成功");
        }
      } catch { }
    },
    /** 提交按钮 */
    async submitForm(isBool, audit) {
      const valid = await this.$refs["form"].validate();
      try {
        this.loading = true;
        if (valid) {
          if (this.inStoreForm.billId) {
            const res = await inStoreUpdateAPI(this.inStoreForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.inStoreForm = res.data;
              this.$modal.msgSuccess(audit ? "审核成功" : "修改单据成功");
              console.log('audit1', audit);
              if (audit != '审核成功') {
                this.$tab.closeOpenPage({ path: "/produce/bill/inStore" });
              }
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并新增单据成功");
            }
          } else {
            const { data } = await inStoreAddAPI(this.inStoreForm);
            //重新赋值给表单，可以把id赋值
            if (isBool) {
              this.inStoreForm = data;
              this.$modal.msgSuccess(audit ? "审核成功" : "新增单据成功");
              if (audit != '审核成功') {
                this.$tab.closeOpenPage({ path: "/produce/bill/inStore" });
              }
            } else {
              await this.reset();
              await this.getOrderBillNo();
              this.$modal.msgSuccess("保存并修改单据成功");
            }
          }
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //保存并新增单据
    async addBill() {
      this.$confirm("是否保存并新增单据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "success",
      })
        .then(async () => {
          try {
            this.loading = true;
            await this.submitForm(false);
          } finally {
            this.loading = false;
          }
        })
        .catch();
    },

    //单据编号
    async getOrderBillNo() {
      this.inStoreForm.billNo = await getBillNo(170203);
    },
    //退出
    getQuit() {
      // 关闭当前tab页签，打开新页签
      this.$tab.closeOpenPage({ path: "/produce/bill/inStore" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      await this.getOrderBillNo();
      await this.getInitializeData();
      this.oldForm = JSON.parse(JSON.stringify(this.inStoreForm));
      this.loading = false;
    },
    //引入源单
    getImportOrder() {
      if (!this.inStoreForm.produceDeptId) return this.$message.error("生产工厂不能为空");
      this.$router.push({
        name: "inStoreImport",
        query: {
          produceDeptId: this.inStoreForm?.produceDeptId,
          type: "inStoreImport",
        },
      });
    },

    //表格增加/减少一行方法
    row(name, index) {
      if (name === "push") {
        this.inStoreForm.details.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.inStoreForm.details.length <= 1) {
          this.inStoreForm.details = [{
            goodsNo: "",
            hoverRow: false,
          }];
        } else {
          this.inStoreForm.details.splice(index, 1);
        }
      }
    },
    //初始化页面数据
    async getInitializeData() {
      this.loading = true;
      //获取单据日期
      this.inStoreForm.billDate = getNewDate();
      const res = await produceDeptList({ produceDeptNo: "0000" });
      if (res.rows[0]?.produceDeptId) {
        //默认生产工厂
        this.$set(this.inStoreForm, "produceDeptId", res.rows[0]?.produceDeptId);
        this.$set(this.inStoreForm, "produceDeptName", res.rows[0]?.produceDeptName);
        // this.changeProduceGroupChange(res.rows[0]?.produceDeptId)
      }
      this.loading = false;
    },

    // 表单重置
    reset() {
      this.isImportSource = false; //是否引入源单
      //新增按钮
      this.showAdd = false;
      //保存禁用
      this.disabled = false;
      this.inStoreForm = {
        billDate: "", //单据日期
        billNo: undefined, //单据编号
        billStatus: "", //单据审核状态
        //单据表格-订单明细
        details: [{}],
      };
      this.resetForm("form");
    },
  },
};
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  // height: calc(100vh - 84px);
  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }
      ::v-deep .el-form-item__error {
        position: static;
      }
      ::v-deep .el-form-item {
        margin-bottom: 0;
      }
    }
  }
}
::v-deep .el-form-item {
  margin-bottom: 20px;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
